import Rollbar, { LogArgument } from "rollbar"

interface RollbarLoggerParameters {
    accessToken: string,
    environmentName: string,
    rollbarArgs?: Record<string, any>,
    showConsoleLogs?: boolean
}

export class RollbarLogger {
    rollbar: Rollbar
    private showConsoleLogs: boolean

    constructor({ accessToken, environmentName, rollbarArgs = {}, showConsoleLogs = false }: RollbarLoggerParameters) {
        this.showConsoleLogs = showConsoleLogs
        this.rollbar = new Rollbar({
            accessToken,
            environment: environmentName,
            captureUncaught: true,
            captureUnhandledRejections: true,
            ...rollbarArgs
        })
    }

    log = (...args: Array<LogArgument>): void => {
        this.rollbar.log(...args)
        if (this.showConsoleLogs) {
            console.log(...args)
        }        
    }

    debug = (...args: Array<LogArgument>): void => {
        this.rollbar.debug(...args)
        if (this.showConsoleLogs) {
            console.debug(...args)
        }
    }

    info = (...args: Array<LogArgument>): void => {
        this.rollbar.info(...args)
        if (this.showConsoleLogs) {
            console.info(...args)
        }
    }

    warning = (...args: Array<LogArgument>): void => {
        this.rollbar.warning(...args)
        if (this.showConsoleLogs) {
            console.warn(...args)
        }
    }

    error = (...args: Array<LogArgument>): void => {
        this.rollbar.error(...args)
        if (this.showConsoleLogs) {
            console.error(...args)
        }
    }

    critical = (...args: Array<LogArgument>): void => {
        this.rollbar.critical(...args)
        if (this.showConsoleLogs) {
            console.error(...args)
        }
    }
}