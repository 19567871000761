import { AirportAction, AirportState, ReduxAction } from "../constants"


export default (prevState: AirportState = {}, action: ReduxAction<AirportAction, AirportState>): AirportState => {
    switch (action.type) {
        case AirportAction.SET_AIRPORT: {
            const { airportId, iataCode, name, iso4217, country, timezone, latitude, longitude } = action.props
            return {
                ...prevState,
                airportId, iataCode, name, iso4217,
                country, timezone, latitude, longitude
            }
        }
        case AirportAction.CLEAR_AIRPORT:
            return {}
        default:
            return prevState
    }
}
