import { CredentialAction, CredentialState, ReduxAction} from "../constants"


export function setCredentials(username: string | null, password: string | null): ReduxAction<CredentialAction, CredentialState> {
    const action = {
        type: CredentialAction.SET_CREDENTIALS,
        props: {
            username,
            password
        }
    }
    return action
}

export function clearCredentials(): ReduxAction<CredentialAction, CredentialState> {
    const action = {
        type: CredentialAction.CLEAR_CREDENTIALS,
        props: {}
    }
    return action
}

export function setEmail(email: string): ReduxAction<CredentialAction, CredentialState> {
    const action = {
        type: CredentialAction.SET_EMAIL,
        props: {
            email
        }
    }
    return action
}
