import React from 'react'
import HomeIcon from "@material-ui/icons/Home"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import StoreIcon from "@material-ui/icons/Store"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import SmartPhoneIcon from "@material-ui/icons/Smartphone"
import BankIcon from "@material-ui/icons/AccountBalance"
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun"
import ActiveIcon from "@material-ui/icons/TransferWithinAStation"
import ReceiptIcon from "@material-ui/icons/Receipt"
import ListIcon from "@material-ui/icons/List"
import AssessmentIcon from "@material-ui/icons/Assessment"
import StarsIcon from "@material-ui/icons/Stars"
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import CouponIcon from "@material-ui/icons/LocalOffer"
import GroupIcon from "@material-ui/icons/Group"
import SettingsIcon from "@material-ui/icons/Settings"

// account actions
export enum AccountAction {
    CREATE_ACCOUNT = "create",
    LOGIN_ACCOUNT = "login"
}

export enum DataGranularity {
    DAILY_DATA = 1,
    HOURLY_DATA = 2
}

// fetchyfox account types
export enum AccountType {
    FOX_ACCOUNT = "fox",
    MERCHANT_ACCOUNT = "merchant",
    AIRPORT_ACCOUNT = "airport"
}

export enum ComponentVariant {
    UPDATE_VIEW = "update",
    CREATE_VIEW = "create"
}

export type DrawerItem = {
    label: string,
    icon: React.ReactElement,
    listed: boolean,
    redirectParams: {
        pathname: string,
        variant?: ComponentVariant
    }
}

// the side menu
export function getDrawerItems(role: AccountType, iso3166: string): Array<DrawerItem> {
    const pages: { [key: string]: Array<DrawerItem> } = {
        [AccountType.FOX_ACCOUNT]: [
            {
                label: "Home",
                icon: <HomeIcon />,
                redirectParams: { pathname: "/dashboard" },
                listed: true,
            },
            {
                label: "Profile Info",
                icon: <AccountCircleIcon />,
                redirectParams: { pathname: "/fox/basic", variant: ComponentVariant.UPDATE_VIEW },
                listed: true,
            },
            {
                label: "Payment Card",
                icon: <CreditCardIcon />,
                redirectParams: { pathname: "/fox/prepaid" },
                listed: true,
            },
            {
                label: "Two-Factor Authentication",
                icon: <SmartPhoneIcon />,
                redirectParams: { pathname: "/fox/mobile", variant: ComponentVariant.UPDATE_VIEW },
                listed: true,
            },
            {
                label: "Stripe Info",
                icon: <BankIcon />,
                redirectParams: { pathname: "/fox/platform", variant: ComponentVariant.UPDATE_VIEW },
                listed: iso3166 === "US",
            },
        ],
        [AccountType.MERCHANT_ACCOUNT]: [
            {
                label: "Home",
                icon: <HomeIcon />,
                redirectParams: { pathname: "/dashboard" },
                listed: true,
            },
            {
                label: "Manage Orders",
                icon: <ReceiptIcon />,
                redirectParams: {pathname: "/merchant/orders"},
                listed: true
            },
            {
                label: "Manage Listings",
                icon: <StoreIcon />,
                redirectParams: { pathname: "/listings" },
                listed: true,
            },
            {
                label: "Product Library",
                icon: <LibraryBooksIcon />,
                redirectParams: { pathname: "/library" },
                listed: true,
            },
            {
                label: "Manage Coupons",
                icon: <CouponIcon />,
                redirectParams: { pathname: "/merchant/coupons" },
                listed: true,
            },
            {
                label: "Team",
                icon: <GroupIcon />,
                redirectParams: { pathname: "/merchant/team" },
                listed: true,
            },
            {
                label: "Location Settings",
                icon: <SettingsIcon/>,
                redirectParams: { pathname: "/merchant/settings"},
                listed: true,
            },
            {
                label: "Your Profile",
                icon: <AccountCircleIcon />,
                redirectParams: { pathname: "/merchant/basic", variant: ComponentVariant.UPDATE_VIEW },
                listed: true,
            },
            {
                label: "Configure Payouts",
                icon: <BankIcon />,
                redirectParams: { pathname: "/merchant/platform" },
                listed: iso3166 === "US",
            }
        ],
        [AccountType.AIRPORT_ACCOUNT]: [
            {
                label: "Home",
                icon: <HomeIcon />,
                redirectParams: { pathname: "/dashboard" },
                listed: true,
            },
            {
                label: "Fox Performance",
                icon: <AssessmentIcon />,
                redirectParams: { pathname: "/airport/fleet" },
                listed: true,
            },
            {
                label: "Reviews",
                icon: <StarsIcon />,
                redirectParams: { pathname: "/airport/reviews" },
                listed: true,
            },
            {
                label: "Airport Listings",
                icon: <ListIcon />,
                redirectParams: { pathname: "/airport/listings" },
                listed: true,
            },
            {
                label: "Payment Cards",
                icon: <CreditCardIcon />,
                redirectParams: { pathname: "/activecards" },
                listed: true,
            },
            {
                label: "Fox Applicants",
                icon: <DirectionsRunIcon />,
                redirectParams: { pathname: `/applicants/${AccountType.FOX_ACCOUNT}` },
                listed: true,
            },
            {
                label: "Merchant Applicants",
                icon: <StoreIcon />,
                redirectParams: { pathname: `/applicants/${AccountType.MERCHANT_ACCOUNT}` },
                listed: true,
            },
            {
                label: "Active Deliveries",
                icon: <ActiveIcon />,
                redirectParams: { pathname: "/activedeliveries" },
                listed: true,
            },
            {
                label: "Order Lookup",
                icon: <ReceiptIcon />,
                redirectParams: { pathname: "/lookup" },
                listed: true,
            },
            {
                label: "Team",
                icon: <GroupIcon />,
                redirectParams: { pathname: "/airport/team" },
                listed: true,
            },            
        ]
    }

    const items:Array<DrawerItem> = pages[role.valueOf()].filter(item => { return item.listed })
    return items
}
