import { combineReducers } from "redux"

import server from "./server"
import credentials from "./credentials"
import airport from "./airport"
import appframe from "./appframe"
import serviceworker from "./serviceworker"
import session from "./session"


export default combineReducers({
    server,
    credentials,
    appframe,
    airport,
    serviceworker,
    session
})

