import { ServerAction, ServerState, ReduxAction } from "../constants"


export function setBackendHost(): ReduxAction<ServerAction, ServerState> {
    const action = {
        type: ServerAction.SET_BACKEND,
        props: {
            host: process.env.REACT_APP_API_HOST
        }
        
    }
    return action
}
