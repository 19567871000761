import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

// the firebase app we use to validate dashboard credentials
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT}.firebaseapp.com`,
    databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT}.firebaseio.com`,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT}.appspot.com`,
}
export const firebaseApp: firebase.app.App = firebase.initializeApp(config, "auth");

// the firebase app that stores delivery information in the fetchyfox platform
const platformConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_PLATFORM_KEY,
    authDomain: `${process.env.REACT_APP_FIREBASE_PLATFORM_PROJECT}.firebaseapp.com`,
    databaseURL: `https://${process.env.REACT_APP_FIREBASE_PLATFORM_PROJECT}.firebaseio.com`,
    projectId: process.env.REACT_APP_FIREBASE_PLATFORM_PROJECT,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PLATFORM_PROJECT}.appspot.com`,
}

export const firebasePlatformApp: firebase.app.App = firebase.initializeApp(platformConfig, "platform")