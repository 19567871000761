import { ServerState, ServerAction, ReduxAction } from "../constants"

export default (prevState: ServerState = {}, action: ReduxAction<ServerAction, ServerState>): ServerState => {
    switch(action.type) {
        case ServerAction.SET_BACKEND: {
            const { host } = action.props
            return {
                ...prevState,
                host
            }
        }
        default:
            return prevState
    }
}
