// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import React, { Suspense, lazy } from 'react';
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { IntlProvider } from "react-intl"
import { Route, Switch, Redirect } from "react-router"
import { createStore } from "redux"
import { BrowserRouter } from "react-router-dom"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { uuid } from "uuidv4"

import { MaterialFoxTheme } from "./components/shared/themes/fetchyfox"
import { AccountType } from "./components/globals"

import reportWebVitals from './reportWebVitals';
import {
    serviceWorkerSuccess, serviceWorkerHasUpdate, serviceWorkerInitialize, serviceWorkerUpdateTimeout,
    setCredentials
} from "./actions"
import { register as registerServiceWorker } from "./serviceWorkerRegistration"
import reducer from "./reducers"
import { firebaseApp } from "./firebase"
import { SuspenseScreen } from "./components/views/Suspense"
import { RollbarLogger } from "./components/shared/logger"

const AppFrameContainer = lazy(() => import("./components/AppFrame"))
const Home = lazy(() => import("./components/Home"))
const Dashboard = lazy(() => import("./components/Dashboard"))
const RequestPasswordReset = lazy(() => import("./components/RequestPasswordReset"))
const PasswordReset = lazy(() => import("./components/PasswordReset"))
const VerifyContactContainer = lazy(() => import("./components/fox/VerifyContact"))
const BasicInfoContainer = lazy(() => import("./components/fox/BasicInfo"))
const PrepaidCard = lazy(() => import("./components/fox/PrepaidCard"))
const StripeInfoContainer = lazy(() => import("./components/StripeInfo"))

const OverviewContainer = lazy(() => import("./components/merchant/Overview"))
const MerchantBasicInfo = lazy(() => import("./components/merchant/BasicInfo"))
const SellableLibraryContainer  = lazy(() => import("./components/merchant/SellableLibrary"))
const ProductLibraryContainer = lazy(() => import("./components/merchant/ProductLibrary"))
const CouponLibraryContainer = lazy(() => import("./components/merchant/CouponLibrary"))
const PoiSettingsContainer = lazy(() => import("./components/merchant/PoiSettings"))
const ActiveOrdersContainer = lazy(() => import("./components/merchant/ActiveOrders"))

const FoxPerformanceContainer = lazy(() => import("./components/airport/FoxPerformance"))
const Applicants = lazy(() => import("./components/airport/Applicants"))
const ActivePaymentCardsContainer = lazy(() => import("./components/airport/ActivePaymentCards"))
const ActiveDeliveriesContainer = lazy(() => import("./components/airport/ActiveDeliveries"))
const OrderLookupContainer = lazy(() => import("./components/airport/OrderLookup"))
const AirportListingsContainer = lazy(() => import("./components/airport/Listings"))
const MenuContainer = lazy(() => import("./components/airport/Menu"))
const ReviewsContainer = lazy(() => import("./components/airport/Reviews"))
const OnboardingContainer = lazy(() => import("./components/Onboarding"))
const TeamContainer = lazy(() => import("./components/Team"))
// import MerchantLegal from "./components/merchant/onboarding/Legal"

const store = createStore(reducer)

// initialize global logger
const rollbar = new RollbarLogger({
    accessToken: process.env.REACT_APP_ROLLBAR_KEY as string,
    environmentName: process.env.REACT_APP_ENV as string,
    showConsoleLogs: process.env.REACT_APP_ENV !== "production"
})


firebaseApp.auth().onAuthStateChanged(user => {
    if (user) {
        user.getIdToken().then((idToken) => {
            store.dispatch(setCredentials(user.email, idToken))
        })
    }
    else {
        store.dispatch(setCredentials(null, null))
    }
})

function renderAuthorized(component: React.ReactElement) {
    const state = store.getState()
    if (state.credentials.username !== undefined && state.credentials.username !== null) {
        return <AppFrameContainer component={component} logger={rollbar} key={uuid()} />
    }
    else {
        return <Redirect to="/" />
    }
}

const App = () => {
    return (
        <IntlProvider locale={navigator.language}>
            <Provider store={store}>
                <MuiThemeProvider theme={MaterialFoxTheme}>
                    <BrowserRouter>
                        <Suspense fallback={<SuspenseScreen />}>
                            <Switch>
                                <Route exact path="/dashboard" render={(props: any) => renderAuthorized(<Dashboard {...props} />)} />
                                <Route path="/fox/onboarding" render={(props: any) => renderAuthorized(<OnboardingContainer variant={AccountType.FOX_ACCOUNT} {...props} />)} />
                                <Route path="/fox/mobile" render={(props: any) => renderAuthorized(<VerifyContactContainer {...props} />)} />
                                <Route path="/fox/basic" render={(props: any) => renderAuthorized(<BasicInfoContainer {...props} />)} />
                                <Route path="/fox/prepaid" render={(props: any) => renderAuthorized(<PrepaidCard {...props} />)} />
                                <Route path="/fox/platform" render={(props: any) => renderAuthorized(<StripeInfoContainer {...props} accountType={AccountType.FOX_ACCOUNT} />)} />

                                <Route path="/merchant/overview" render={(props: any) => renderAuthorized(<OverviewContainer {...props} />)} />
                                <Route path="/merchant/onboarding" render={(props: any) => renderAuthorized(<OnboardingContainer variant={AccountType.MERCHANT_ACCOUNT} {...props} />)} />
                                <Route path="/merchant/basic" render={(props: any) => renderAuthorized(<MerchantBasicInfo {...props} />)} />
                                <Route path="/merchant/platform" render={(props: any) => renderAuthorized(<StripeInfoContainer {...props} accountType={AccountType.MERCHANT_ACCOUNT} />)} />
                                <Route exact path="/library" render={(props: any) => renderAuthorized(<ProductLibraryContainer {...props} />)} />
                                <Route path="/listings" render={(props: any) => renderAuthorized(<SellableLibraryContainer {...props} />)} />
                                <Route path="/merchant/coupons" render={(props: any) => renderAuthorized(<CouponLibraryContainer {...props} />)} />
                                <Route path="/merchant/team" render={(props: any) => renderAuthorized(<TeamContainer {...props} variant={AccountType.MERCHANT_ACCOUNT} />)} />
                                <Route path="/merchant/settings" render={(props: any) => renderAuthorized(<PoiSettingsContainer {...props} />)} />
                                <Route path="/merchant/orders" render={(props: any) => renderAuthorized(<ActiveOrdersContainer {...props} />)} />

                                <Route path="/airport/onboarding" render={(props: any) => renderAuthorized(<OnboardingContainer variant={AccountType.AIRPORT_ACCOUNT} {...props} />)} />
                                <Route path="/applicants/:applicantType" render={(props: any) => renderAuthorized(<Applicants {...props} />)} />
                                <Route path="/activedeliveries" render={(props: any) => renderAuthorized(<ActiveDeliveriesContainer {...props} />)} />
                                <Route path="/activecards" render={(props: any) => renderAuthorized(<ActivePaymentCardsContainer {...props} />)} />
                                <Route path="/lookup" render={(props: any) => renderAuthorized(<OrderLookupContainer {...props} />)} />
                                <Route path="/airport/listings" render={(props: any) => renderAuthorized(<AirportListingsContainer {...props} />)} />
                                <Route path="/airport/listing/:placeId" render={(props: any) => renderAuthorized(<MenuContainer {...props} />)} />
                                <Route path="/airport/fleet" render={(props: any) => renderAuthorized(<FoxPerformanceContainer {...props} />)} />
                                <Route path="/airport/reviews" render={(props: any) => renderAuthorized(<ReviewsContainer {...props} />)} />
                                <Route path="/airport/team" render={(props: any) => renderAuthorized(<TeamContainer {...props} variant={AccountType.AIRPORT_ACCOUNT} />)} />

                                <Route path="/:accountType/forgot" render={(props: any) => (
                                    <AppFrameContainer component={<RequestPasswordReset {...props} />} logger={rollbar} key={uuid()} />
                                )} />
                                <Route path="/fox/reset/:token" render={(props: any) => (
                                    <AppFrameContainer component={<PasswordReset {...props} />} logger={rollbar} key={uuid()} />
                                )} />

                                <Route exact path="/:iata?" render={(props: any) => (<AppFrameContainer component={<Home {...props} />} logger={rollbar} key={uuid()} />)} />


                            </Switch>
                        </Suspense>
                    </BrowserRouter>
                </MuiThemeProvider>
            </Provider>
        </IntlProvider>
    )
}




ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
store.dispatch(serviceWorkerInitialize())
registerServiceWorker({
    onSuccess: () => {
        store.dispatch(serviceWorkerSuccess())
    },
    onUpdate: (registration) => {
        store.dispatch(serviceWorkerHasUpdate(registration))
    }
});

window.setTimeout(() => store.dispatch(serviceWorkerUpdateTimeout()), 10000)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
